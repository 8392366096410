import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

export abstract class BasicForm {
  fields: Array<FormlyFieldConfig>;
  form: UntypedFormGroup;

  constructor(public model: any, public options: FormlyFormOptions = {}) {
    this.form = new UntypedFormGroup({});

    setTimeout(() => {
      this.initFields();
    });
  }

  abstract initFields(): void;
}
